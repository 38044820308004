import React from 'react';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IMfrRepHeadsTable {
  item: any;
  index: Number;
  handleRemove: any;
}

const MfrRepHeadsTable = (props: IMfrRepHeadsTable) => {
  return (
    <tr>
      <td>{props.item.email}</td>
      <td>
        {[...props.item.regions, ...props.item.states].map((location, index) => (
          <p key={index}>{location}</p>
        ))}
      </td>
      <td style={{ display: 'flex', justifyContent: 'end', paddingRight: '10px', marginTop: '-3px' }}>
        <Button color="danger" id="save-entity" type="button" onClick={() => props.handleRemove(props.item.email)} className="btn-sm">
          <FontAwesomeIcon icon="trash" />
        </Button>
      </td>
    </tr>
  );
};

export default MfrRepHeadsTable;
