import { Moment } from 'moment';
import { IProjectProduct } from 'app/shared/model/project-product.model';

export const enum ProjectStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SENT = 'SENT',
  APPROVED = 'APPROVED',
  REVISE_RESUBMIT = 'REVISE_RESUBMIT',
  CLOSED = 'CLOSED',
}

export const enum ForgeProjectStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = ' COMPLETE',
  FAILURE = ' FAILURE',
  GENERATING_RVT = ' GENERATING_RVT',
  GENERATING_DWG = ' GENERATING_DWG',
  GENERATING_ZIP = ' GENERATING_ZIP',
}

export interface IProject {
  id?: number;
  projectName?: string;
  isRoomDesign?: boolean;
  roomlePlanId?: string;
  jobId?: number;
  revitProjectFile?: string;
  thumbnailImage?: string;
  clientName?: string;
  dateCreated?: Moment;
  lastModified?: Moment;
  status?: ProjectStatus;
  guid?: string;
  autoDeskId?: string;
  outputZipUrl?: any;
  outputReportUrl?: any;
  forgeStatus?: ForgeProjectStatus;
  projectCode?: string;
  projectStreetAddress?: string;
  projectCity?: string;
  projectState?: string;
  projectZipCode?: string;
  userCompanyName?: string;
  userStreetAddress?: string;
  userCity?: string;
  userState?: string;
  userZipCode?: string;
  userCompanyUrl?: string;
  userPhone?: string;
  drawnBy?: string;
  marketSegment?: string;
  shareBy?: string;
  seen?: boolean;
  revitExportEnable?: boolean;
  itemNumber?: string;
  externalFileUrl?: string;
  configLink?: string;
  occupation?: string;
  followUp?: string;
  comments?: string;
  requestOrigin?: string;
  manufacturerIds?: string;
  equipmentDealer?: string;
  fcmToken?: string;
  exportWrittenSpecs?: boolean;
  sheetSizes?: string;
  country?: string;
  test?: boolean;
  userLogin?: string;
  userId?: number;
  projectProducts?: IProjectProduct[];
  manufacturerName?: string;
  manufacturerId?: number;
}

export const defaultValue: Readonly<IProject> = {
  isRoomDesign: false,
  seen: false,
  revitExportEnable: false,
  exportWrittenSpecs: false,
  test: false,
};
