import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {getEntity} from './user-configuration.reducer';

export interface IUserConfigurationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const UserConfigurationDetail = (props: IUserConfigurationDetailProps) => {
    useEffect(() => {
        props.getEntity(props.match.params.id);
    }, []);

    const {userConfigurationEntity} = props;
    return (
        <Row>
            <Col md="8">
                <h2>
                    UserConfiguration [<b>{userConfigurationEntity.id}</b>]
                </h2>
                <dl className="jh-entity-details">
                    <dt>User</dt>
                    <dd>{userConfigurationEntity.userLogin ? userConfigurationEntity.userLogin : ''}</dd>
                    <dt>
                        <span id="configuration">Configuration</span>
                    </dt>
                    <dd>{userConfigurationEntity.configuration}</dd>
                    <dt>
                        <span id="visible">Visible</span>
                    </dt>
                    <dd>{userConfigurationEntity.visible ? 'true' : 'false'}</dd>
                    <dt>
                        <span id="modelNumber">Model Number</span>
                    </dt>
                    <dd>{userConfigurationEntity.modelNumber}</dd>
                    <dt>
                        <span id="description">Description</span>
                    </dt>
                    <dd>{userConfigurationEntity.description}</dd>
                    <dt>
                        <span id="thumbnailImage">Thumbnail Image</span>
                    </dt>
                    <dd>{userConfigurationEntity.thumbnailImage}</dd>
                    <dt>
                        <span id="configSize">Config Size</span>
                    </dt>
                    <dd>{userConfigurationEntity.configSize}</dd>
                    <dt>
                        <span id="configSizeInch">Config Size Inch</span>
                    </dt>
                    <dd>{userConfigurationEntity.configSizeInch}</dd>
                    <dt>
                        <span id="cutsheetUrl">Cutsheet Url</span>
                    </dt>
                    <dd>{userConfigurationEntity.cutsheetUrl}</dd>
                    <dt>
                        <span id="typename">Typename</span>
                    </dt>
                    <dd>{userConfigurationEntity.typename}</dd>
                    <dt>
                        <span id="typecatalogheader">Typecatalogheader</span>
                    </dt>
                    <dd>{userConfigurationEntity.typecatalogheader}</dd>
                    <dt>
                        <span id="typecatalog">Typecatalog</span>
                    </dt>
                    <dd>{userConfigurationEntity.typecatalog}</dd>

                    <dt>
                        <span id="baseFamily">Base Family</span>
                    </dt>
                    <dd>{userConfigurationEntity.baseFamily}</dd>
                    <dt>
                        <span id="composite">Composite</span>
                    </dt>
                    <dd>
                        <div>
                            {userConfigurationEntity.composite && (
                                <dd style={{background: 'lightgrey', borderRadius: '10px'}}>
                                    <pre>{JSON.stringify(userConfigurationEntity.composite, null, 2)}</pre>
                                </dd>
                            )}
                        </div>
                    </dd>
                    <dt>Product</dt>
                    <dd>{userConfigurationEntity.productTypename ? userConfigurationEntity.productTypename : ''}</dd>
                    <dt>Folder</dt>
                    <dd>{userConfigurationEntity.folderName ? userConfigurationEntity.folderName : ''}</dd>
                </dl>
                <Button tag={Link} to="/user-configuration" replace color="info">
                    <FontAwesomeIcon icon="arrow-left"/> <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp;
                <Button tag={Link} to={`/user-configuration/${userConfigurationEntity.id}/edit`} replace
                        color="primary">
                    <FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">Edit</span>
                </Button>
            </Col>
        </Row>
    );
};

const mapStateToProps = ({userConfiguration}: IRootState) => ({
    userConfigurationEntity: userConfiguration.entity,
});

const mapDispatchToProps = {getEntity};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserConfigurationDetail);
