import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { createEntity, getEntity, reset, updateEntity } from './manufacturer.reducer';
import ManufacturerFileUpload from 'app/entities/manufacturer/ManufacturerFileUpload';
import { Button, Form, FormGroup, Input, Label, Table } from 'reactstrap';
import { Field, Form as ReactFinalForm } from 'react-final-form';
import AdminEmailSettings from 'app/entities/manufacturer/AdminEmailSettings';
import { getMfrRepByMfrAndRegion, getRegions, getStates } from 'app/entities/manufacturer-ref-data/manufacturer-ref-data.reducer';
import Sidebar from './EditMfrSidebar';
import { setIsSidebarCollapsed, setIsFullScreenMode } from '../user-settings/user-settings.reducer';
import Divider from 'app/components/Divider/Divider';
import Select, { MultiValue } from 'react-select';
import MfrRepHeadsTable from './mfrRepHeadsTable';

export interface IManufacturerUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

interface ImanufacturerRepEmails {
  regionOrState: string;
  email: string;
}

interface IMfrRepHead {
  email: string;
  regions: string[];
  states: string[];
}

export const ManufacturerUpdate = (props: IManufacturerUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [manufacturerRepEmails, setManufacturerRepEmails] = useState<ImanufacturerRepEmails[]>([]);
  const [regionOrState, setRegionOrStateOrState] = useState('');
  const [email, setEmail] = useState('');
  const [emailType, setEmailType] = useState('DROPDOWN');
  const [activeTab, setActiveTab] = useState(1);
  const [activeSendgridTemplateTab, setActiveSendgridTemplateTab] = useState(1);

  const [mfrRepHeads, setMfrRepHeads] = useState<IMfrRepHead[]>([]);
  const [mfrRepHeadEmail, setMfrRepHeadEmail] = useState<string>('');
  const [mfrRepHeadEmailRegions, setMfrRepHeadEmailRegions] = useState<MultiValue<any>>([]);
  const [mfrRepHeadEmailStates, setMfrRepHeadEmailStates] = useState<MultiValue<any>>([]);
  const [mfrRepHeadType, setMfrRepHeadType] = useState('BY_STATE');

  const { manufacturerEntity, loading, updating } = props;
  const handleClose = () => {
    props.history.push('/manufacturer' + props.location.search);
  };
  useEffect(() => {
    if (manufacturerEntity.id) {
      props.getStates();
      props.getRegions();
      props.getMfrRepByMfrAndRegion(manufacturerEntity.name, null);
    }
  }, [manufacturerEntity]);

  const getManufacturerRefByRegions = regionData => {
    if (manufacturerEntity.id) {
      props.getMfrRepByMfrAndRegion(manufacturerEntity.name, regionData);
    }
  };

  useEffect(() => {
    props.setIsSidebarCollapsed(true);
    props.setIsFullScreenMode(true);
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
    return () => {
      props.setIsSidebarCollapsed(false);
      props.setIsFullScreenMode(false);
    };
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    if (!props.loading && props.manufacturerEntity.id) {
      setManufacturerRepEmails(
        props.manufacturerEntity.manufacturerRepEmails !== null && props.manufacturerEntity.manufacturerRepEmails !== ''
          ? JSON.parse(props.manufacturerEntity.manufacturerRepEmails)
          : []
      );

      setMfrRepHeads(
        props.manufacturerEntity.manufacturerRepHeadEmails !== null && props.manufacturerEntity.manufacturerRepHeadEmails !== ''
          ? JSON.parse(props.manufacturerEntity.manufacturerRepHeadEmails)
          : []
      );
    }
  }, [props.manufacturerEntity, props.loading]);

  const saveEntity = values => {
    const entity = {
      ...manufacturerEntity,
      ...values,
      manufacturerRepEmails: JSON.stringify(manufacturerRepEmails),
      manufacturerRepHeadEmails: JSON.stringify(mfrRepHeads),
    };

    if (isNew) {
      props.createEntity(entity);
    } else {
      props.updateEntity(entity);
    }
  };

  const identity = value => value;
  const required = value => (value ? undefined : <small color="danger">This field is required</small>);

  const handleRemove = index => {
    const newManufacturerRepEmails = [...manufacturerRepEmails.filter((item, i) => i !== index)];
    setManufacturerRepEmails(newManufacturerRepEmails);
  };

  const handleAddRepHead = () => {
    if (mfrRepHeadEmail && (mfrRepHeadEmailRegions.length || mfrRepHeadEmailStates.length)) {
      const newRepHead: IMfrRepHead = {
        email: mfrRepHeadEmail,
        regions: mfrRepHeadEmailRegions.map(region => region.value) || null,
        states: mfrRepHeadEmailStates.map(state => state.value) || null,
      };

      setMfrRepHeads([...mfrRepHeads, newRepHead]);

      setMfrRepHeadEmail('');
      setMfrRepHeadEmailRegions([]);
      setMfrRepHeadEmailStates([]);
    }
  };

  const handleRemoveRepHead = (emailToRemove: string) => {
    setMfrRepHeads(mfrRepHeads.filter(head => head.email !== emailToRemove));
  };

  const filteredItems = (items, emailItems, key) => {
    return items
      .filter(item => {
        return !emailItems.includes(item) && !mfrRepHeads.some(head => head[key].includes(item));
      })
      .map(item => ({
        label: item,
        value: item,
      }));
  };

  const filteredRegions = () => {
    return filteredItems(props.regions, mfrRepHeadEmailRegions, 'regions');
  };

  const filteredStates = () => {
    return filteredItems(props.states, mfrRepHeadEmailStates, 'states');
  };

  return (
    <div className="edit-manufacturer-page-container">
      <Sidebar activeTab={activeTab} setActiveTab={setActiveTab} />

      <div className="edit-manufacturer-content-container">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <ReactFinalForm
              initialValues={manufacturerEntity}
              onSubmit={saveEntity}
              validate={values => {
                const errors = {};
                return errors;
              }}
              render={({ handleSubmit, form, values }) => {
                useEffect(() => {
                  if (values.mrfRefEmailType == 'BY_REGION') {
                    setRegionOrStateOrState('');
                    setEmail('');
                    setManufacturerRepEmails([]);
                  }
                }, [values.mrfRefEmailType]);
                return (
                  <Form noValidate onSubmit={handleSubmit}>
                    <header className="edit-manufacturer-form-header-container">
                      <div className="edit-manufacturer-form-header">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div className="edit-manufacturer-form-header-avatar">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path
                                d="M11.9998 13.8V15.6C10.5676 15.6 9.19412 16.1689 8.18143 17.1816C7.16873 18.1943 6.5998 19.5678 6.5998 21H4.7998C4.7998 19.0904 5.55837 17.2591 6.90864 15.9088C8.2589 14.5586 10.0902 13.8 11.9998 13.8ZM11.9998 12.9C9.0163 12.9 6.5998 10.4835 6.5998 7.50001C6.5998 4.51651 9.0163 2.10001 11.9998 2.10001C14.9833 2.10001 17.3998 4.51651 17.3998 7.50001C17.3998 10.4835 14.9833 12.9 11.9998 12.9ZM11.9998 11.1C13.9888 11.1 15.5998 9.48901 15.5998 7.50001C15.5998 5.51101 13.9888 3.90001 11.9998 3.90001C10.0108 3.90001 8.3998 5.51101 8.3998 7.50001C8.3998 9.48901 10.0108 11.1 11.9998 11.1ZM14.3353 18.1308C14.2212 17.6506 14.2212 17.1503 14.3353 16.6701L13.4425 16.1544L14.3425 14.5956L15.2353 15.1113C15.5937 14.7719 16.0268 14.5216 16.4998 14.3805V13.35H18.2998V14.3805C18.7786 14.5227 19.2106 14.7765 19.5643 15.1113L20.4571 14.5956L21.3571 16.1544L20.4643 16.6701C20.5783 17.15 20.5783 17.65 20.4643 18.1299L21.3571 18.6456L20.4571 20.2044L19.5643 19.6887C19.206 20.0281 18.7728 20.2785 18.2998 20.4195V21.45H16.4998V20.4195C16.0268 20.2785 15.5937 20.0281 15.2353 19.6887L14.3425 20.2044L13.4425 18.6456L14.3353 18.1308ZM17.3998 18.75C17.7578 18.75 18.1012 18.6078 18.3544 18.3546C18.6076 18.1014 18.7498 17.758 18.7498 17.4C18.7498 17.042 18.6076 16.6986 18.3544 16.4454C18.1012 16.1922 17.7578 16.05 17.3998 16.05C17.0418 16.05 16.6984 16.1922 16.4452 16.4454C16.192 16.6986 16.0498 17.042 16.0498 17.4C16.0498 17.758 16.192 18.1014 16.4452 18.3546C16.6984 18.6078 17.0418 18.75 17.3998 18.75Z"
                                fill="#525866"
                              />
                            </svg>
                          </div>
                          {activeTab === 1 && <span>Manufacturer Details</span>}
                          {activeTab === 2 && <span>MFR CRM Integrations</span>}
                          {activeTab === 3 && <span>Email Templates</span>}
                          {activeTab === 4 && <span>Email Addresses</span>}
                          {activeTab === 5 && <span>MFR Rep Head Regions</span>}
                        </div>

                        <Button
                          color="primary"
                          id="save-entity"
                          type="submit"
                          disabled={updating}
                          className="edit-manufacturer-save-button"
                        >
                          Save Settings
                        </Button>
                      </div>
                      <Divider length="100%" />
                    </header>
                    {activeTab === 1 && (
                      <>
                        <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                          <span className="edit-manufacturer-form-input-label required">User ID</span>
                          <Field
                            render={({ input, meta }) => (
                              <div className={'w-50'}>
                                <Input {...input} className="edit-manufacturer-form-input"></Input>
                                {meta.touched && meta.error && <span>{meta.error}</span>}
                              </div>
                            )}
                            parse={identity}
                            placeholder={''}
                            name="userId"
                            validate={required}
                          />
                        </FormGroup>

                        <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                          <span className="edit-manufacturer-form-input-label required">Manufacturer Name</span>
                          <Field
                            render={({ input, meta }) => (
                              <div className={'w-50'}>
                                <Input {...input} className="edit-manufacturer-form-input"></Input>
                                {meta.touched && meta.error && <span>{meta.error}</span>}
                              </div>
                            )}
                            parse={identity}
                            placeholder={''}
                            name="name"
                            validate={required}
                          />
                        </FormGroup>

                        <div className="edit-manufacturer-divider-container">
                          <Divider length="100" />
                        </div>

                        <FormGroup className="edit-manufacturer-form-checkbox-container">
                          <Field
                            render={({ input, meta }) => (
                              <>
                                <Input
                                  type={'checkbox'}
                                  checked={input.value}
                                  onChange={event => input.onChange(event.target.checked)}
                                  {...input}
                                  className="edit-manufacturer-form-checkbox"
                                />
                                {meta.touched && meta.error && <span>{meta.error}</span>}
                              </>
                            )}
                            parse={identity}
                            placeholder={''}
                            type="checkbox"
                            name="enabled"
                          />
                          <Label for="exampleEmail" className="edit-manufacturer-form-input-label">
                            Enabled
                          </Label>
                        </FormGroup>

                        <div className="edit-manufacturer-divider-container">
                          <Divider length="100" />
                        </div>

                        <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                          <span className="edit-manufacturer-form-input-label required">Company Logo</span>
                          <Field
                            render={({ input, meta }) => (
                              <div className={'w-50 d-flex justify-center align-items-center'}>
                                {manufacturerEntity.companyLogo ? (
                                  <img src={manufacturerEntity.companyLogo} alt="upload file icon" className="edit-manufacturer_mfr-logo" />
                                ) : (
                                  <img src="../../../content/images/file-upload.svg" alt="upload file icon" />
                                )}
                                <ManufacturerFileUpload setOutput={data => input.onChange(data)} />
                                {meta.touched && meta.error && <span>{meta.error}</span>}
                              </div>
                            )}
                            parse={identity}
                            placeholder={''}
                            name="companyLogo"
                            validate={required}
                          />
                        </FormGroup>

                        <div className="edit-manufacturer-divider-container">
                          <Divider length="100" />
                        </div>

                        <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                          <span className="edit-manufacturer-form-input-label">Support Email</span>
                          <Field
                            render={({ input, meta }) => (
                              <div className={'w-50'}>
                                <Input {...input} className="edit-manufacturer-form-input" />
                                {meta.touched && meta.error && <span>{meta.error}</span>}
                              </div>
                            )}
                            parse={identity}
                            placeholder={''}
                            name="supportEmail"
                          />
                        </FormGroup>

                        <div className="edit-manufacturer-divider-container">
                          <Divider length="100" />
                        </div>

                        <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                          <span className="edit-manufacturer-form-input-label required">City</span>
                          <Field
                            render={({ input, meta }) => (
                              <div className={'w-50'}>
                                <Input {...input} className="edit-manufacturer-form-input" />
                                {meta.touched && meta.error && <span>{meta.error}</span>}
                              </div>
                            )}
                            parse={identity}
                            placeholder={''}
                            name="city"
                          />
                        </FormGroup>

                        <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                          <span className="edit-manufacturer-form-input-label required">State</span>
                          <Field
                            render={({ input, meta }) => (
                              <div className={'w-50'}>
                                <Input {...input} className="edit-manufacturer-form-input" />
                                {meta.touched && meta.error && <span>{meta.error}</span>}
                              </div>
                            )}
                            parse={identity}
                            placeholder={''}
                            name="state"
                          />
                        </FormGroup>

                        <div className="edit-manufacturer-divider-container">
                          <Divider length="100" />
                        </div>

                        <FormGroup className="edit-manufacturer-form-checkbox-container">
                          <Field
                            render={({ input, meta }) => (
                              <>
                                <Input
                                  type={'checkbox'}
                                  checked={input.value}
                                  onChange={event => input.onChange(event.target.checked)}
                                  {...input}
                                  className="edit-manufacturer-form-checkbox"
                                />
                                {meta.touched && meta.error && <span>{meta.error}</span>}
                              </>
                            )}
                            parse={identity}
                            placeholder={''}
                            type="checkbox"
                            name="showMrfInventoryDetails"
                          />
                          <Label for="exampleEmail" className="edit-manufacturer-form-input-label">
                            Show Mfr in Inventory Details
                          </Label>
                        </FormGroup>

                        <div className="edit-manufacturer-divider-container">
                          <Divider length="100" />
                        </div>

                        <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                          <span className="edit-manufacturer-form-input-label">Sheet Sizes</span>
                          <Field
                            render={({ input, meta }) => (
                              <div className={'w-50'}>
                                <Select
                                  defaultValue={input.value ? JSON.parse(input.value) : []}
                                  value={input.value ? JSON.parse(input.value) : []}
                                  placeholder="Select Revit Titleblock Size(s)…"
                                  isMulti
                                  name="sheetSizes"
                                  options={[
                                    {
                                      value: '8.5x11',
                                      label: '8.5x11',
                                    },
                                    {
                                      value: '11x17',
                                      label: '11x17',
                                    },
                                    {
                                      value: '24x36',
                                      label: '24x36',
                                    },
                                  ]}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={value => {
                                    form.change('sheetSizes', JSON.stringify(value));
                                  }}
                                />
                              </div>
                            )}
                            parse={identity}
                            placeholder={''}
                            name="sheetSizes"
                          />
                        </FormGroup>

                        <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                          <span className="edit-manufacturer-form-input-label required">Website URL</span>
                          <Field
                            render={({ input, meta }) => (
                              <div className={'w-50'}>
                                <Input {...input} className="edit-manufacturer-form-input" />
                                {meta.touched && meta.error && <span>{meta.error}</span>}
                              </div>
                            )}
                            parse={identity}
                            placeholder={''}
                            name="websiteURL"
                          />
                        </FormGroup>

                        <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                          <span className="edit-manufacturer-form-input-label">Admin Email</span>
                          <Field
                            render={({ input, meta }) => (
                              <div className={'w-50'}>
                                <Input {...input} className="edit-manufacturer-form-input" />
                                {meta.touched && meta.error && <span>{meta.error}</span>}
                              </div>
                            )}
                            parse={identity}
                            placeholder={''}
                            name="adminEmail"
                          />
                        </FormGroup>
                      </>
                    )}

                    {activeTab === 2 && (
                      <>
                        <FormGroup className="edit-manufacturer-form-checkbox-container">
                          <Field
                            render={({ input, meta }) => (
                              <>
                                <Input
                                  type={'checkbox'}
                                  checked={input.value}
                                  onChange={event => input.onChange(event.target.checked)}
                                  {...input}
                                  className="edit-manufacturer-form-checkbox"
                                />
                                {meta.touched && meta.error && <span>{meta.error}</span>}
                              </>
                            )}
                            parse={identity}
                            placeholder={''}
                            type="checkbox"
                            name="hubspotEnable"
                          />
                          <Label for="exampleEmail" className="edit-manufacturer-form-input-label">
                            Hubspot Enable
                          </Label>
                        </FormGroup>

                        <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                          <span className="edit-manufacturer-form-input-label">Hubspot API Key</span>
                          <Field
                            render={({ input, meta }) => (
                              <div className={'w-50'}>
                                <Input {...input} className="edit-manufacturer-form-input" />
                                {meta.touched && meta.error && <span>{meta.error}</span>}
                              </div>
                            )}
                            parse={identity}
                            placeholder={''}
                            name="hubspotApiKey"
                          />
                        </FormGroup>

                        <div className="edit-manufacturer-divider-container">
                          <Divider length="100" />
                        </div>

                        <FormGroup className="edit-manufacturer-form-checkbox-container">
                          <Field
                            render={({ input, meta }) => (
                              <>
                                <Input
                                  type={'checkbox'}
                                  checked={input.value}
                                  onChange={event => input.onChange(event.target.checked)}
                                  {...input}
                                  className="edit-manufacturer-form-checkbox"
                                />
                                {meta.touched && meta.error && <span>{meta.error}</span>}
                              </>
                            )}
                            parse={identity}
                            placeholder={''}
                            type="checkbox"
                            name="sendgridEnabled"
                          />
                          <Label for="exampleEmail" className="edit-manufacturer-form-input-label">
                            SendGrid Enable
                          </Label>
                        </FormGroup>

                        <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                          <span className="edit-manufacturer-form-input-label">Sendgrid API Key</span>
                          <Field
                            render={({ input, meta }) => (
                              <div className={'w-50'}>
                                <Input {...input} className="edit-manufacturer-form-input" />
                                {meta.touched && meta.error && <span>{meta.error}</span>}
                              </div>
                            )}
                            parse={identity}
                            placeholder={''}
                            name="sendgridApiKey"
                          />
                        </FormGroup>

                        <div className="edit-manufacturer-divider-container">
                          <Divider length="100" />
                        </div>

                        <FormGroup className="edit-manufacturer-form-checkbox-container">
                          <Field
                            render={({ input, meta }) => (
                              <>
                                <Input
                                  type={'checkbox'}
                                  checked={input.value}
                                  onChange={event => input.onChange(event.target.checked)}
                                  {...input}
                                  className="edit-manufacturer-form-checkbox"
                                />
                                {meta.touched && meta.error && <span>{meta.error}</span>}
                              </>
                            )}
                            parse={identity}
                            placeholder={''}
                            type="checkbox"
                            name="sharpSpringEnable"
                          />
                          <Label for="exampleEmail" className="edit-manufacturer-form-input-label">
                            SharpSpring Enable
                          </Label>
                        </FormGroup>

                        <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                          <span className="edit-manufacturer-form-input-label">SharpSpring Account ID</span>
                          <Field
                            render={({ input, meta }) => (
                              <div className={'w-50'}>
                                <Input {...input} className="edit-manufacturer-form-input" />
                                {meta.touched && meta.error && <span>{meta.error}</span>}
                              </div>
                            )}
                            parse={identity}
                            placeholder={''}
                            name="sharpSpringAccountID"
                          />
                        </FormGroup>

                        <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                          <span className="edit-manufacturer-form-input-label">SharpSpring Secret Key</span>
                          <Field
                            render={({ input, meta }) => (
                              <div className={'w-50'}>
                                <Input {...input} className="edit-manufacturer-form-input" />
                                {meta.touched && meta.error && <span>{meta.error}</span>}
                              </div>
                            )}
                            parse={identity}
                            placeholder={''}
                            name="sharpSpringSecretKey"
                          />
                        </FormGroup>
                      </>
                    )}

                    {activeTab === 3 && (
                      <>
                        <div className="edit-manufacturer-email-template-tab-buttons-container">
                          <button
                            type="button"
                            className={`edit-manufacturer-email-template-tab-button ${
                              activeSendgridTemplateTab === 1 ? 'edit-manufacturer-email-template-tab-button--active' : ''
                            }`}
                            onClick={() => setActiveSendgridTemplateTab(1)}
                          >
                            Share Revit Template
                          </button>
                          <button
                            type="button"
                            className={`edit-manufacturer-email-template-tab-button ${
                              activeSendgridTemplateTab === 2 ? 'edit-manufacturer-email-template-tab-button--active' : ''
                            }`}
                            onClick={() => setActiveSendgridTemplateTab(2)}
                          >
                            Share Configuration Template
                          </button>
                          <button
                            type="button"
                            className={`edit-manufacturer-email-template-tab-button ${
                              activeSendgridTemplateTab === 3 ? 'edit-manufacturer-email-template-tab-button--active' : ''
                            }`}
                            onClick={() => setActiveSendgridTemplateTab(3)}
                          >
                            Project Template ID
                          </button>
                          <button
                            type="button"
                            className={`edit-manufacturer-email-template-tab-button ${
                              activeSendgridTemplateTab === 4 ? 'edit-manufacturer-email-template-tab-button--active' : ''
                            }`}
                            onClick={() => setActiveSendgridTemplateTab(4)}
                          >
                            Floor Plan Template ID
                          </button>
                          <button
                            type="button"
                            className={`edit-manufacturer-email-template-tab-button ${
                              activeSendgridTemplateTab === 5 ? 'edit-manufacturer-email-template-tab-button--active' : ''
                            }`}
                            onClick={() => setActiveSendgridTemplateTab(5)}
                          >
                            Download drawing Template ID
                          </button>
                        </div>

                        {activeSendgridTemplateTab === 1 && (
                          <>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">Share Revit Template</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="shareRevitTemplate"
                              />
                            </FormGroup>
                            <div className="edit-manufacturer-divider-container">
                              <Divider length="100" />
                            </div>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">From</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="shareRevitTemplateFrom"
                              />
                            </FormGroup>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">CC</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="shareRevitTemplateCc"
                              />
                            </FormGroup>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">BCC</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="shareRevitTemplateBcc"
                              />
                            </FormGroup>
                          </>
                        )}
                        {activeSendgridTemplateTab === 2 && (
                          <>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">Share Configuration Template</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="shareConfigurationTemplate"
                              />
                            </FormGroup>
                            <div className="edit-manufacturer-divider-container">
                              <Divider length="100" />
                            </div>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">From</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="shareConfigurationFrom"
                              />
                            </FormGroup>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">CC</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="shareConfigurationCc"
                              />
                            </FormGroup>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">CC</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="shareConfigurationBcc"
                              />
                            </FormGroup>
                          </>
                        )}
                        {activeSendgridTemplateTab === 3 && (
                          <>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">User Project Template ID</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="projectTemplateUser"
                              />
                            </FormGroup>
                            <div className="edit-manufacturer-divider-container">
                              <Divider length="100" />
                            </div>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">From</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="projectTemplateUserFrom"
                              />
                            </FormGroup>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">CC</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="projectTemplateUserCc"
                              />
                            </FormGroup>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">BCC</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="projectTemplateUserBcc"
                              />
                            </FormGroup>
                            <div className="edit-manufacturer-divider-container">
                              <Divider length="100" />
                            </div>

                            <FormGroup className="edit-manufacturer-form-checkbox-container">
                              <Field
                                render={({ input, meta }) => (
                                  <>
                                    <Input
                                      type={'checkbox'}
                                      checked={input.value}
                                      onChange={event => input.onChange(event.target.checked)}
                                      {...input}
                                      className="edit-manufacturer-form-checkbox"
                                    />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </>
                                )}
                                parse={identity}
                                placeholder={''}
                                type="checkbox"
                                name="attachWrittenSpecification"
                              />
                              <Label for="attachWrittenSpecification" className="edit-manufacturer-form-input-label">
                                Send Email with attach specification
                              </Label>
                            </FormGroup>

                            <FormGroup className="edit-manufacturer-form-checkbox-container">
                              <Field
                                render={({ input, meta }) => (
                                  <>
                                    <Input
                                      type={'checkbox'}
                                      checked={input.value}
                                      onChange={event => input.onChange(event.target.checked)}
                                      {...input}
                                      className="edit-manufacturer-form-checkbox"
                                    />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </>
                                )}
                                parse={identity}
                                placeholder={''}
                                type="checkbox"
                                name="sendMfrRepHeadEmail"
                              />
                              <Label for="sendMfrRepHeadEmail" className="edit-manufacturer-form-input-label">
                                Send Manufacturer Rep Head Email
                              </Label>
                            </FormGroup>

                            <FormGroup className="edit-manufacturer-form-checkbox-container">
                              <Field
                                render={({ input, meta }) => (
                                  <>
                                    <Input
                                      type={'checkbox'}
                                      checked={input.value}
                                      onChange={event => input.onChange(event.target.checked)}
                                      {...input}
                                      className="edit-manufacturer-form-checkbox"
                                    />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </>
                                )}
                                parse={identity}
                                placeholder={''}
                                type="checkbox"
                                name="sendMfrRepEmail"
                              />
                              <Label for="sendMfrRepEmail" className="edit-manufacturer-form-input-label">
                                Send manufacturer Rep Email
                              </Label>
                            </FormGroup>

                            <div className="edit-manufacturer-divider-container">
                              <Divider length="100" />
                            </div>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">Manufacturer Project Template ID</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="projectTemplateManufacturer"
                              />
                            </FormGroup>
                            <div className="edit-manufacturer-divider-container">
                              <Divider length="100" />
                            </div>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">From</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="projectTemplateManufacturerFrom"
                              />
                            </FormGroup>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">To</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="projectTemplateManufacturerTo"
                              />
                            </FormGroup>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">CC</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="projectTemplateManufacturerCc"
                              />
                            </FormGroup>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">BCC</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="projectTemplateManufacturerBcc"
                              />
                            </FormGroup>
                          </>
                        )}
                        {activeSendgridTemplateTab === 4 && (
                          <>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">User Floor Plan Template ID</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="floorPlanUserTemplate"
                              />
                            </FormGroup>
                            <div className="edit-manufacturer-divider-container">
                              <Divider length="100" />
                            </div>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">From</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="floorPlanUserFrom"
                              />
                            </FormGroup>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">CC</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="floorPlanUserCc"
                              />
                            </FormGroup>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">BCC</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="floorPlanUserBcc"
                              />
                            </FormGroup>
                            <div className="edit-manufacturer-divider-container">
                              <Divider length="100" />
                            </div>

                            <FormGroup className="edit-manufacturer-form-checkbox-container">
                              <Field
                                render={({ input, meta }) => (
                                  <>
                                    <Input
                                      type={'checkbox'}
                                      checked={input.value}
                                      onChange={event => input.onChange(event.target.checked)}
                                      {...input}
                                      className="edit-manufacturer-form-checkbox"
                                    />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </>
                                )}
                                parse={identity}
                                placeholder={''}
                                type="checkbox"
                                name="floorPlanUserSendTwoEmail"
                              />
                              <Label for="exampleEmail" className="edit-manufacturer-form-input-label">
                                Send Two Email
                              </Label>
                            </FormGroup>

                            <FormGroup className="edit-manufacturer-form-checkbox-container">
                              <Field
                                render={({ input, meta }) => (
                                  <>
                                    <Input
                                      type={'checkbox'}
                                      checked={input.value}
                                      onChange={event => input.onChange(event.target.checked)}
                                      {...input}
                                      className="edit-manufacturer-form-checkbox"
                                    />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </>
                                )}
                                parse={identity}
                                placeholder={''}
                                type="checkbox"
                                name="sendFloorPlanMfrRepHeadEmail"
                              />
                              <Label for="sendFloorPlanMfrRepHeadEmail" className="edit-manufacturer-form-input-label">
                                Send Manufacturer Rep Head Email
                              </Label>
                            </FormGroup>

                            <FormGroup className="edit-manufacturer-form-checkbox-container">
                              <Field
                                render={({ input, meta }) => (
                                  <>
                                    <Input
                                      type={'checkbox'}
                                      checked={input.value}
                                      onChange={event => input.onChange(event.target.checked)}
                                      {...input}
                                      className="edit-manufacturer-form-checkbox"
                                    />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </>
                                )}
                                parse={identity}
                                placeholder={''}
                                type="checkbox"
                                name="sendFloorPlanMfrRepEmail"
                              />
                              <Label for="sendFloorPlanMfrRepEmail" className="edit-manufacturer-form-input-label">
                                Send manufacturer Rep Email
                              </Label>
                            </FormGroup>

                            <div className="edit-manufacturer-divider-container">
                              <Divider length="100" />
                            </div>

                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">Manufacturer Floor Plan Template ID</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="floorPlanManufacturerTemplate"
                              />
                            </FormGroup>
                            <div className="edit-manufacturer-divider-container">
                              <Divider length="100" />
                            </div>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">From</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="floorPlanManufacturerFrom"
                              />
                            </FormGroup>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">To</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="floorPlanManufacturerTo"
                              />
                            </FormGroup>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">CC</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="floorPlanManufacturerCc"
                              />
                            </FormGroup>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">BCC</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="floorPlanManufacturerBcc"
                              />
                            </FormGroup>
                          </>
                        )}

                        {activeSendgridTemplateTab === 5 && (
                          <>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">Download drawing Template ID</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="downloadDrawingTemplate"
                              />
                            </FormGroup>
                            <div className="edit-manufacturer-divider-container">
                              <Divider length="100" />
                            </div>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">From</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="downloadDrawingFrom"
                              />
                            </FormGroup>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">CC</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="downloadDrawingCc"
                              />
                            </FormGroup>
                            <FormGroup className="mb-3 edit-manufacturer-form-input-container">
                              <span className="edit-manufacturer-form-input-label">BCC</span>
                              <Field
                                render={({ input, meta }) => (
                                  <div className={'w-50'}>
                                    <Input {...input} className="edit-manufacturer-form-input" />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                                parse={identity}
                                placeholder={''}
                                name="downloadDrawingBcc"
                              />
                            </FormGroup>
                          </>
                        )}
                      </>
                    )}

                    {activeTab === 4 && (
                      <>
                        <div className="d-flex justify-content-center align-items-end">
                          <div style={{ width: '45%' }}>
                            <div className="edit-manufacturer-toggle-container">
                              <Field
                                checked={values.mrfRefEmailType === 'BY_STATE'}
                                className="edit-manufacturer-toggle-field edit-manufacturer-toggle-field--by-state"
                                name="mrfRefEmailType"
                                component="input"
                                type="radio"
                                value="BY_STATE"
                              />

                              <Field
                                checked={values.mrfRefEmailType === 'BY_REGION'}
                                className="edit-manufacturer-toggle-field edit-manufacturer-toggle-field--by-region"
                                name="mrfRefEmailType"
                                component="input"
                                type="radio"
                                value="BY_REGION"
                              />
                            </div>
                            <div style={{ width: '45%' }}>
                              {values.mrfRefEmailType == 'BY_REGION' ? (
                                <Input
                                  value={regionOrState}
                                  type="select"
                                  className="email-settings-project-select"
                                  onChange={event => {
                                    const regionData = event.target.value;
                                    setRegionOrStateOrState(regionData);
                                    getManufacturerRefByRegions(regionData);
                                  }}
                                >
                                  <option value={null}>Select a region</option>
                                  {props.regions.length > 0
                                    ? props.regions.map(item => {
                                        return <option value={item}>{item}</option>;
                                      })
                                    : ''}
                                </Input>
                              ) : (
                                <Input
                                  value={regionOrState}
                                  type="select"
                                  className="email-settings-project-select"
                                  onChange={event => {
                                    const regionData = event.target.value;
                                    setRegionOrStateOrState(regionData);
                                  }}
                                >
                                  <option value={null}>Select a state</option>
                                  {props.states.length > 0
                                    ? props.states.map(item => {
                                        return <option value={item}>{item}</option>;
                                      })
                                    : ''}
                                </Input>
                              )}
                            </div>
                          </div>
                          <div style={{ width: '65%' }}>
                            <div className="edit-manufacturer-toggle-container">
                              <Input
                                checked={emailType === 'DROPDOWN'}
                                component="input"
                                type="radio"
                                value="DROPDOWN"
                                onChange={() => setEmailType('DROPDOWN')}
                                className="edit-manufacturer-toggle-field edit-manufacturer-toggle-field--select-from"
                              />

                              <Input
                                checked={emailType === 'MANUAL_SELECTION'}
                                component="input"
                                type="radio"
                                value="MANUAL_SELECTION"
                                onChange={() => setEmailType('MANUAL_SELECTION')}
                                className="edit-manufacturer-toggle-field edit-manufacturer-toggle-field--manual"
                              />
                            </div>

                            {emailType === 'DROPDOWN' ? (
                              <Input
                                value={email}
                                type="select"
                                className="email-settings-project-select"
                                onChange={event => setEmail(event.target.value)}
                              >
                                <option value={null}>Select a region/state admin</option>
                                {props.manufacturerRepEntities.length > 0
                                  ? props.manufacturerRepEntities.map(item => {
                                      return (
                                        <option value={item.email}>
                                          {item.email} - {item.company}, {item.mailingAddress}, {item.city}, {item.country}
                                        </option>
                                      );
                                    })
                                  : ''}
                              </Input>
                            ) : (
                              <Input
                                value={email}
                                type="text"
                                placeholder="Enter email"
                                className="email-settings-project-select"
                                onChange={event => setEmail(event.target.value)}
                              ></Input>
                            )}
                          </div>
                          <div style={{ width: '10%', marginLeft: '10px' }}>
                            <Button
                              color="primary"
                              className="add-email-settings-project-button"
                              disabled={!regionOrState || !email}
                              onClick={() => {
                                if (regionOrState && email) {
                                  setManufacturerRepEmails([...manufacturerRepEmails, { regionOrState: regionOrState, email: email }]);
                                  setRegionOrStateOrState('');
                                  setEmail('');
                                }
                              }}
                            >
                              <FontAwesomeIcon icon="plus" />
                            </Button>
                          </div>
                        </div>

                        <br></br>
                        <Table responsive borderless className="edit-manufacturer-emails-table">
                          <thead className="edit-manufacturer-emails-table-header">
                            <tr>
                              <th>Region</th>
                              <th>Admin Email</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {manufacturerRepEmails.map((item, index) => (
                              <AdminEmailSettings index={index} item={item} key={index} handleRemove={handleRemove} />
                            ))}
                          </tbody>
                        </Table>
                      </>
                    )}

                    {activeTab === 5 && (
                      <>
                        <div className="d-flex justify-content-center align-items-end">
                          <div style={{ width: '100%' }}>
                            <div className="edit-manufacturer-toggle-container">
                              <input
                                checked={mfrRepHeadType === 'BY_STATE'}
                                className="edit-manufacturer-toggle-field edit-manufacturer-toggle-field--by-state"
                                type="radio"
                                onChange={() => setMfrRepHeadType(mfrRepHeadType === 'BY_STATE' ? 'BY_REGION' : 'BY_STATE')}
                              />
                              <input
                                checked={mfrRepHeadType === 'BY_REGION'}
                                className="edit-manufacturer-toggle-field edit-manufacturer-toggle-field--by-region"
                                type="radio"
                                onChange={() => setMfrRepHeadType(mfrRepHeadType === 'BY_STATE' ? 'BY_REGION' : 'BY_STATE')}
                              />
                            </div>
                            <div style={{ width: '70%' }}>
                              {mfrRepHeadType === 'BY_REGION' ? (
                                <Field
                                  render={({ input }) => (
                                    <div className={'w-100'}>
                                      <Select
                                        value={mfrRepHeadEmailRegions}
                                        isMulti
                                        name="regions"
                                        options={filteredRegions()}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select Region"
                                        onChange={value => setMfrRepHeadEmailRegions(value)}
                                      />
                                    </div>
                                  )}
                                  name="regions"
                                />
                              ) : (
                                <Field
                                  render={({ input }) => (
                                    <div className={'w-100'}>
                                      <Select
                                        value={mfrRepHeadEmailStates}
                                        isMulti
                                        name="states"
                                        options={filteredStates()}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select State"
                                        onChange={value => setMfrRepHeadEmailStates(value)}
                                      />
                                    </div>
                                  )}
                                  name="states"
                                />
                              )}
                            </div>
                          </div>

                          <div style={{ width: '30%' }}>
                            <Input
                              value={mfrRepHeadEmail}
                              type="text"
                              placeholder="Enter email"
                              className="email-settings-project-select"
                              onChange={event => setMfrRepHeadEmail(event.target.value)}
                            />
                          </div>

                          <div style={{ width: '10%', marginLeft: '10px' }}>
                            <Button
                              color="primary"
                              className="add-email-settings-project-button"
                              disabled={!mfrRepHeadEmail && (!mfrRepHeadEmailRegions.length || !mfrRepHeadEmailStates.length)}
                              onClick={handleAddRepHead}
                            >
                              <FontAwesomeIcon icon="plus" />
                            </Button>
                          </div>
                        </div>

                        <br></br>
                        <Table responsive borderless className="edit-manufacturer-emails-table">
                          <thead className="edit-manufacturer-emails-table-header">
                            <tr>
                              <th>Head Email</th>
                              <th>Regions</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {mfrRepHeads.map((item, index) => (
                              <MfrRepHeadsTable index={index} item={item} key={index} handleRemove={handleRemoveRepHead} />
                            ))}
                          </tbody>
                        </Table>
                      </>
                    )}
                  </Form>
                );
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  manufacturerEntity: storeState.manufacturer.entity,
  loading: storeState.manufacturer.loading,
  updating: storeState.manufacturer.updating,
  updateSuccess: storeState.manufacturer.updateSuccess,

  states: storeState.manufacturerRefData.states,
  regions: storeState.manufacturerRefData.regions,
  loadingRegions: storeState.manufacturerRefData.loadingRegions,
  userRegion: storeState.authentication.region,
  manufacturerRep: storeState.manufacturerRefData.entity,
  manufacturerRepEntities: storeState.manufacturerRefData.entities,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  setIsSidebarCollapsed,
  setIsFullScreenMode,
  getRegions,
  getStates,
  getMfrRepByMfrAndRegion,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturerUpdate);
