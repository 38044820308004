import React, { useEffect, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'reactstrap';
import BaseLayout from '../BaseLayout';
import DrawingsSetupTable from './DrawingsSetupTable';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { getEntities, createEntity, deleteEntity } from 'app/entities/drawing-setup/drawing-setup.reducer';
import './drawingSetup.scss';

const DrawingSetup = props => {
  const [requestOrigin, setRequestOrigin] = useState('');
  const [drawingType, setDrawingType] = useState('');
  const [revitProjectFile, setRevitProjectFile] = useState('');

  useEffect(() => {
    props.getEntities();
  }, []);

  const handleAddRepHead = () => {
    if (requestOrigin && drawingType && revitProjectFile) {
      const newSetup = {
        requestOrigin,
        drawingType,
        revitProjectFile,
      };

      setRequestOrigin('');
      setDrawingType('');
      setRevitProjectFile('');

      props.createEntity(newSetup);
    }
  };

  return (
    <BaseLayout title={'Drawings Setup'}>
      <div className="d-flex justify-space-between align-items-center">
        <Input
          placeholder={'Enter request origin'}
          value={requestOrigin}
          onChange={e => setRequestOrigin(e.target.value)}
          className="drawing-setup-input"
        />

        <Input
          placeholder={'Enter drawing type'}
          value={drawingType}
          onChange={e => setDrawingType(e.target.value)}
          className="drawing-setup-input"
        />

        <Input
          placeholder={'Enter revit project file'}
          value={revitProjectFile}
          onChange={e => setRevitProjectFile(e.target.value)}
          className="drawing-setup-input"
        />

        <div style={{ width: '10%', marginLeft: '10px' }}>
          <Button
            color="primary"
            className="add-email-settings-project-button"
            disabled={!requestOrigin && !drawingType && !revitProjectFile}
            onClick={handleAddRepHead}
          >
            <FontAwesomeIcon icon="plus" />
          </Button>
        </div>
      </div>

      <br></br>
      <Table responsive borderless className="edit-manufacturer-emails-table">
        <thead className="edit-manufacturer-emails-table-header">
          <tr>
            <th>Request Origin</th>
            <th>Drawing Type</th>
            <th>Revit Project Type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.drawingsSetupList.map((item, index) => (
            <DrawingsSetupTable index={index} item={item} key={index} handleRemove={props.deleteEntity} />
          ))}
        </tbody>
      </Table>
    </BaseLayout>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  drawingsSetupList: storeState.drawingSetup.entities,
});

const mapDispatchToProps = {
  getEntities,
  createEntity,
  deleteEntity,
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawingSetup);
