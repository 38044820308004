import { IUserConfiguration } from 'app/shared/model/user-configuration.model';

export const enum RoomleOrigin {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
}

export const enum ProductType {
  SINGLE_FAMILY = 'SINGLE_FAMILY',
  SINGLE_FAMILY_WITH_ACCESSORIES = 'SINGLE_FAMILY_WITH_ACCESSORIES',
  LINE_UP_FAMILY = 'LINE_UP_FAMILY',
}

export interface IProduct {
  id?: number;
  name?: string;
  description?: string;
  typeCatalogPath?: string;
  familyFilePath?: string;
  roomleComponentId?: string;
  availableRevitVersions?: string;
  familyParametersWhiteList?: string;
  configuration?: string;
  isMOC?: boolean;
  visibleToAdmin?: boolean;
  showInMarketPlace?: boolean;
  revitExportEnable?: boolean;
  typecatalog?: any;
  typecatalogheader?: any;
  rootComponentId?: string;
  showRevitSetMountingHeight?: boolean;
  cleanRevitFamily?: boolean;
  active?: boolean;
  hideSelectedOptions?: boolean;
  hideParts?: boolean;
  userConfigurations?: IUserConfiguration[];
  manufacturerName?: string;
  manufacturerId?: number;
  image?: string;
  parameters?: string;
  familyFilename?: string;
  fileContentType?: string;
  selectComponentAfterDocking?: boolean;
  baseURL?: string;
  roomleOrigin?: RoomleOrigin;
  revitProjectFile?: string;
  exportWrittenSpecs?: boolean;
  roomleTenantId?: string;
  productType?: ProductType;
}

export const defaultValue: Readonly<IProduct> = {
  isMOC: false,
  visibleToAdmin: false,
  showInMarketPlace: false,
  revitExportEnable: false,
  showRevitSetMountingHeight: false,
  cleanRevitFamily: false,
  active: false,
  hideSelectedOptions: false,
  hideParts: false,
  selectComponentAfterDocking: false,
  roomleOrigin: RoomleOrigin.LEFT,
};
