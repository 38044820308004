import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import DrawingSetup from './drawing-setup';
import DrawingSetupDetail from './drawing-setup-detail';
import DrawingSetupUpdate from './drawing-setup-update';
import DrawingSetupDeleteDialog from './drawing-setup-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={DrawingSetupUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={DrawingSetupUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={DrawingSetupDetail} />
      <ErrorBoundaryRoute path={match.url} component={DrawingSetup} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={DrawingSetupDeleteDialog} />
  </>
);

export default Routes;
